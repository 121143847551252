<template>
  <v-row class="reported_questionnaires">
    <v-col cols="12" class="sub-title ml-2">
      {{ `${$t("reported")}  ${$t("questionnaires")}` }}
    </v-col>
    <v-col cols="12">
      <v-expansion-panels class="pr-1" v-model="openPanel">
        <v-expansion-panel
          v-for="(answerGroup, key) in questionnaire.answers"
          :key="key"
        >
          <v-expansion-panel-header
            class="pa-6"
            :expand-icon="openPanel == key ? 'mdi-minus' : 'mdi-plus'"
          >
            <span class="d-flex-inline pt-2 pb-2 date">
              {{ key }}
            </span>
            <span class="float-right flex-2 score">
              {{ $t("score") }} <strong>{{ answerGroup.score.score }}</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3 class="d-inline-block">{{ $t("patient_responses") }}</h3>
            <h4 class="float-right d-inline-block edit-response">
              <v-icon>icon-edit</v-icon> {{ $t("edit_responses") }}
            </h4>

            <template v-for="(answers, questionType) in answerGroup.data">
              <v-simple-table
                class="mt-8"
                v-if="questionType == 'radio'"
                :key="questionType"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        v-for="(option, optionIndex) in answers[0].question
                          .options"
                        :key="optionIndex"
                        class="text-center text-uppercase"
                      >
                        {{ option.locales[0].option_text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(answer, answerIndex) in answers"
                      :key="answerIndex"
                    >
                      <td>
                        <span class="mr-2">{{ answerIndex + 1 }}.</span>
                        {{ answer.question.locales[0].question_text }}
                      </td>
                      <td
                        v-for="availaleOption in answer.question.options"
                        :key="availaleOption.id"
                      >
                        <div
                          class="circle rounded-circle"
                          :class="{
                            selected:
                              answer.option &&
                              availaleOption.id == answer.option.id,
                          }"
                        >
                          <v-icon
                            v-if="
                              answer.option &&
                              availaleOption.id == answer.option.id
                            "
                          >
                            icon-tick
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MARSQuestionnairePanels",

  props: {
    questionnaire: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      openPanel: null,
    };
  },
};
</script>